<template>
  <div>
    <h2 class="radio-title">
      Bienvenue sur l'Absolu Radio
      <br/><i>Salle performance</i>
    </h2>
    <div class="explain">
      Cette page permet de controler le compte spotify branché aux haut-parleurs de la salle performance.<br />
      La connexion étant plutot instable, il est possible que la tablette ne soit pas toujours contrôlable
      malgré qu'elle soit en activité.
    </div>
    <PulseLoader v-if="loading"/>
    <template v-else-if="playerState && playerState.item">
      <div
        id="player"
      >
        <div class="img-cover">
          <img
            :src="playerState.item.album.images[0].url"
            :class="{pause: !playerState.is_playing || stateLoading, }"
          />
          <ScaleLoader v-if="stateLoading" />
        </div>
        <div class="titles">
          <div class="title">{{playerState.item.name}}</div>
          <div class="subtitle">
            {{playerState.item.artists.map((c, ) => c.name, ).join(", ", )}}
          </div>
        </div>
        <!-- <input
          class="duration"
          type="range"
          v-model="progress"
          step="1"
          :max="playerState.item.duration_ms"
          disabled
        /> -->
        <div class="buttons">
          <button @click="playpause">
            <i
              class="fa"
              :class="!playerState.is_playing ? 'fa-circle-play' : 'fa-circle-pause'"
            />
          </button>
          <button @click="nextVideo">
            <i class="fa fa-forward-step" />
          </button>
        </div>
        <!-- <div class="volume">
          <i class="fa fa-volume-up" />
          <input
            type="range"
            v-model="playingVolume"
            step="1"
            min="0"
            max="100"
            @change="handleChangeVolume"
          />
        </div> -->
        <p>Un décalage de minimum 5s peut avoir lieu du aux limitations de l'API de Spotify</p>
      </div>
      <Songs
        spotify
      />
    </template>
    <div
      v-else
      class="img-container"
    >
      <div>
        <h2>Le compte spotify n'est pas actif ou la connexion a été interrompue.</h2>
        <p>Patientez ou réessayez</p>
        <img :src="require('@/assets/empty.png', )" />
        <div class="button-container">
          <button @click="getPlayback">Réessayer</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import config from "@/config";
import { mapGetters } from "vuex";
import Api from "@/modules/axios";
// import SpotifyQueue from "@/components/SpotifyQueue.vue";
import Songs from "@/components/Songs.vue";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  name: "PlayerControl",
  components: {
    // SpotifyQueue,
    Songs,
    ScaleLoader,
  },
  data() {
    return {
      // playingVolume: 0,
      playerState: null,
      loading: true,
      playerToken: null,
      progress: 0,

      stateLoading: false,
    };
  },
  computed: {
    ...mapGetters(["token", "isAdmin"]),
  },
  mounted() {
    if (!this.isAdmin) {
      return this.$router.push({ name: "404" });
    }

    this.$store.commit("setAvatarShow", false);

    this.loading = true;
    this.getPlayback()
      .then(() => {
        this.loading = false;
      });

    setInterval(() => {
      this.getPlayback();
    }, 5000);
    return true;
  },
  methods: {
    getPlayback() {
      return Api()
        .post("/demande/spotify/")
        .then((res) => {
          this.playerState = res.data.playback;

          if (res.data.playback) {
            this.progress = res.data.playback.progress_ms;
            // this.playingVolume = res.data.playback.device.volume_percent;
          }
          return true;
        });
    },
    // handleChangeVolume() {
    //   this.stateLoading = true;
    //   return Api()
    //     .post("/demande/spotify_volume/", { volume: this.playingVolume })
    //     .then(() => {
    //       this.stateLoading = false;
    //     });
    // },
    nextVideo() {
      this.stateLoading = true;
      return Api()
        .post("/demande/spotify_next/")
        .then((res) => {
          if (res.data.ok) {
            setTimeout(() => {
              this.getPlayback().then(() => {
                this.stateLoading = false;
              });
            }, 2000);
          }
        });
    },
    playpause() {
      this.stateLoading = true;
      const target = !this.playerState.is_playing;
      return Api()
        .post(`/demande/spotify_playpause/?pause=${target ? 0 : 1}`)
        .then((res) => {
          if (res.data.ok) {
            this.$set(this.playerState, "is_playing", target);
          }

          this.stateLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

.explain {
  text-align: center;
  margin-bottom: 2rem;
}

#player {
  text-align: center;
  border-bottom: 1px solid var.$border-gray;
  padding-bottom: 1rem;
  img {
    width: 50%;
    &.pause {
      opacity: 0.6;
    }
  }
  .buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
    button {
      background: none;
      border: none;
      i {
        font-size: 2.5rem;
      }
    }
  }
  .title {
    font-weight: bold;
    font-size: 1.2rem;
  }
  // .volume {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   margin-top: 2rem;
  //   input {
  //     width: 40%;
  //   }
  //   .fa {
  //     margin-right: 1rem;
  //   }
  // }
}

.duration {
  margin-bottom: 2rem;
  width: 100% ;
}

.img-cover {
  position: relative;
  .v-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

</style>
